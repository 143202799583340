import { AxiosInstance } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { FT_USERS_ADMIN_OPERATOR } from "constants/request";
import { UserAccount } from "store/user/user";

export type FetchOperators = typeof fetchOperators;
export type FetchOperatorsData = {
  clientId: number;
};

export const fetchOperators = createAsyncThunk<
  UserAccount | any,
  {
    request: AxiosInstance;
    data: FetchOperatorsData;
  }
>("operators/fetchOperators", async ({ request, data }) => {
  const response = await request(FT_USERS_ADMIN_OPERATOR, {
    method: "GET",
    params: {
      clientId: data.clientId,
      pageNumber: 1,
      pageSize: 200,
    },
  });
  return response.data.results;
  /* return [
    {
      id: 213,
      givenName: "first",
      familyName: "second",
    },
    {
      id: 123,
      givenName: "first1",
      familyName: "second1",
    },
    {
      id: 122,
      givenName: "first2",
      familyName: "second2",
    },
  ]; */
});
